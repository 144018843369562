@import "src/colors.scss";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.flex-splitter + .flex-splitter {
  margin-top: 2px;
}

#gototop {
  position: fixed;
  bottom: 16px;
  right: 24px;
  width: 36px;
  height: 36px;
  background-color: $sea;
  color: $white;
  border-radius: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 0 1px rgba(1, 0, 0, 0.1);
}
#gototop:hover {
  opacity: 1 !important;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 0 4px rgba(1, 0, 0, 0.1);
}

html:not([data-scroll='0']) #gototop {
  opacity: 0.65;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  color: $black;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#light {
  color: $black;
  background-color: white;
}

#root {
  width: 100%;
  height: 100%;
  font-family: inherit !important;
}

button,
input[type='submit'],
input[type='reset'],
a {
  text-transform: inherit;
  text-decoration: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

code {
  font-family: 'Overpass Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
