$petal: rgb(77, 199, 87); //#f98866;
$poppy: #ff420e;
$stem: #80bd9e;
$spring: #89da59;
$engie: #7eda59;
$sea: #56F06B;

$white: rgb(252, 252, 252);
$lessWhite: rgb(214, 214, 214);
$black: #272727;
$gray: #727272;